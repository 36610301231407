@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Vollkorn:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Hornbill';
	src: url('../public/fonts/Hornbill Font/mf861626.otf');
}

@layer utilities {
	/* Scroll thumb styles */
	.scrollbar::-webkit-scrollbar {
		width: 0.5rem;
		margin: 4rem 0;
	}
	.scrollbar::-webkit-scrollbar-thumb {
		background: #80beaf;
		border-radius: 1rem;
	}
	.scrollbar::-webkit-scrollbar-thumb:active {
		background-color: #507f74;
	}
	.scrollbar::-webkit-scrollbar-thumb:hover {
		background: #507f74;
		box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
	}

	/* Scroll track styles (Background) */
	.scrollbar::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 4px;
	}
	.scrollbar::-webkit-scrollbar-track:hover,
	.scrollbar::-webkit-scrollbar-track:active {
		background: #f1f1f1;
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	input[type='range']::-webkit-slider-thumb {
		pointer-events: auto;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		-webkit-appearance: none;
		@apply bg-green-600;

		/* @apply w-6 h-6 appearance-none pointer-events-auto; */
	}
	.screen-no-header {
		height: calc(100vh - 5rem);
	}
	.screen-1\/2 {
		height: 50vh;
	}
	.screen-3\/4 {
		height: 75vh;
	}
}

@layer base {
	html {
		@apply text-black-default;
	}
}

@layer components {
	.link-sm {
		@apply text-green-600 underline cursor-pointer text-sm;
	}

	.card {
		@apply bg-white w-full py-8 px-12 shadow-lighter-grey hover:shadow-light-grey rounded-xl min-h-full ;
	}

}
